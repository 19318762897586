/**
 * Themes
 */

@import '../Common/Variables';
@import '../Common/Mixins';

.theme-1 {
    @include theme-sidebar(#fff, $text-color, #FF8700, #fff);
    // @include theme-header(#31569F, #fff);
    @include theme-header(#ffffff, #fff);

    
    // @include theme-header(#FF8700, #fff);
    // @include theme-sidebar(#fff, $text-color, $mdc-blue-700, #fff);
    // @include theme-header($mdc-blue-700, #fff);
}

.theme-2 {
    @include theme-sidebar($mdc-blue-grey-900, #fff, $mdc-blue-grey-900, #fff);
    @include theme-header($mdc-blue-700, #fff);
    @include theme-content($mdc-blue-grey-50);
}

.theme-3 {
    @include theme-sidebar($mdc-blue-grey-900, #fff, $mdc-blue-grey-900, #fff);
    @include theme-header($mdc-blue-grey-800, #fff);
    @include theme-content-dark($mdc-blue-grey-800);
    @include theme-sidebar-items($brand-primary, $brand-success);
}

.theme-4 {
    @include theme-sidebar($mdc-blue-grey-900, #fff, $mdc-blue-grey-900, #fff);
    @include theme-header($brand-success, #fff);
    @include theme-content($gray-lighter);
    @include theme-sidebar-items($brand-success, lighten($brand-success, 25%));
}

.theme-5 {
    @include theme-sidebar($mdc-blue-grey-900, #fff, $mdc-blue-grey-900, #fff);
    @include theme-header($brand-info, #fff);
    @include theme-content($gray-lighter);
    @include theme-sidebar-items($brand-info, lighten($brand-info, 25%));
}

.theme-6 {
    @include theme-sidebar($mdc-blue-grey-900, #fff, $mdc-blue-grey-900, #fff);
    @include theme-header($mdc-deep-purple-300, #fff);
    @include theme-content($gray-lighter);
    @include theme-sidebar-items($mdc-deep-purple-300, lighten($mdc-deep-purple-300, 25%));
}

.theme-7 {
    @include theme-sidebar(#fff, $text-color, $mdc-blue-grey-700, #fff);
    @include theme-header($mdc-blue-grey-700, #fff);
    @include theme-content($mdc-blue-grey-50);
}

.theme-8 {
    @include theme-sidebar($mdc-blue-grey-900, #fff, $mdc-blue-grey-900, #fff);
    @include theme-header(#fff, $text-color);
    @include theme-content($mdc-blue-grey-50);
    @include theme-sidebar-items($brand-primary, #f1f1f1);
}

.theme-9 {
    @include theme-sidebar(#fff, $text-color, #fff, $text-color);
    @include theme-header(#fff, $text-color);
    @include theme-content($mdc-blue-grey-50);
    @include theme-sidebar-items(darken($mdc-blue-grey-300, 10%), $mdc-blue-grey-300);
}

// Theme previews for settings panel
.preview-theme-1 { @include preview-theme($mdc-blue-700,#fff,#f1f2f3); }
.preview-theme-2 { @include preview-theme($mdc-blue-700, $mdc-blue-grey-900,$mdc-blue-grey-50); }
.preview-theme-3 { @include preview-theme($mdc-blue-grey-800, $mdc-blue-grey-900, $mdc-blue-grey-800); }
.preview-theme-4 { @include preview-theme($brand-success, #fff, $gray-lighter); }
.preview-theme-5 { @include preview-theme($brand-info, #fff, $gray-lighter); }
.preview-theme-6 { @include preview-theme($mdc-deep-purple-300, #fff, $gray-lighter); }
.preview-theme-7 { @include preview-theme($mdc-blue-grey-700, #fff, $mdc-blue-grey-50); }
.preview-theme-8 { @include preview-theme(#fff, $mdc-blue-grey-900,$mdc-blue-grey-50); }
.preview-theme-9 { @include preview-theme(#fff, #fff,$mdc-blue-grey-50); }
